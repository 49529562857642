@font-face {
  font-family: outfit;
  src: url(../fonts/Outfit-Regular.ttf);
}

@font-face {
  font-family: outfit-bold;
  src: url(../fonts/Outfit-SemiBold.ttf);
}

@font-face {
  font-family: outfit-thin;
  src: url(../fonts/Outfit-Light.ttf);
}

@font-face {
  font-family: andasia;
  src: url(../fonts/Andasia.ttf);
}

@font-face {
  font-family: rocky;
  src: url(../fonts/Rockybilly.ttf);
}

.pageWrapper {
  position: relative;
  tex-align: center;
  user-select: none;
  background-color: whitesmoke;
  @media (min-width: 1540px) {
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.scrollContainer {
  width: 100%;
  max-width: 1540px;
  height: 100dvh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollContainer::-webkit-scrollbar {
  display: none;
}

.scrollSection {
  scroll-snap-align: center;
  min-height: 100%;
  overflow: hidden;
}

.imageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.textContainer {
  padding: 7rem 1rem;
  z-index: 97;
  font-family: outfit;
  inset: 0;
  position: absolute;
  @media (min-width: 1240px) {
    padding: 7rem 5.6rem;
  }
}

.textOne,
.textTwo,
.textThree {
  z-index: 99;
  font-size: 70px;
  right: 20px;
  font-weight: bold;
  display: flex;
  color: whitesmoke;
  @media (min-width: 620px) {
    font-size: 80px;
  }
  @media (min-width: 800px) {
    font-size: 100px;
  }
  @media (min-width: 1240px) {
    font-size: 130px;
  }
}

.textThree {
  position: absolute;
  justify-content: end;
  font-family: rocky;
  font-size: 45px;
  top: 11rem;
  color: whitesmoke;
  @media (max-width: 374px) {
    font-size: 45px;
    top: 16rem;
  }
  @media (min-width: 620px) {
    font-size: 55px;
    top: 10rem;
    right: 4rem;
  }
  @media (min-width: 800px) {
    font-size: 80px;
    top: 10rem;
    right: 4rem;
  }
  @media (min-width: 1240px) {
    font-size: 100px;
    top: 9rem;
    right: 4rem;
  }
}

.listContainer,
.listFooter {
  position: fixed;
  width: 100%;
  bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
  z-index: 99;
}

.list {
  font-family: outfit;
  padding: 10px;
  font-size: 18px;
  color: #cccccc;
  border: none;
  cursor: pointer;
  background: none;
  transition: color 0.3s;
}

.list.active {
  color: black;
}

.aboutSection,
.contactSection,
.portfolioSection {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.portfolioSection {
  @media (min-width: 820px) {
    flex-direction: row;
    align-items: center;
  }
}
.aboutPageDesktop {
  display: flex;
  width: 100%;
  align-items: center;
}

.aboutSection {
  background-color: whitesmoke;
  justify-content: start;
  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.contactSection {
  position: relative;
  background-color: whitesmoke;
}

.portfolioSection {
  background-color: whitesmoke;
  overflow: hidden;
  justify-content: space-between;
}

.image {
  width: 100%;
  object-fit: cover;
  z-index: 97;
  object-position: center;
  @media (min-width: 820px) {
    width: 33%;
  }
}

.imageMobile {
  object-fit: cover;
}

.aboutImage {
  display: flex;
  align-items: flex-end;
  padding-bottom: 4rem;
  background-image: linear-gradient(to bottom, transparent 30%, #f5f5f5 80%),
    url(./assets/tilde.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  @media (min-width: 1024px) {
    width: 50%;
  }
}
.aboutTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  @media (min-width: 767px) {
    width: 50%;
  }
  background-color: transparent;
  font-family: outfit;

  > p {
    font-family: outfit-thin;
    margin: 0;
  }
}
.aboutTextDesktop {
  mix-blend-mode: difference;
  color: whitesmoke;
  padding: 8rem;
}
.bubblesAboutPage {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(180deg);
}

.aboutTextMobile {
  margin-bottom: 1rem;
  padding: 2rem;
  > p {
    font-family: outfit-thin;
    margin: 0;
  }
  > h2 {
    font-family: outfit;
  }
  @media (min-width: 620px) {
    margin-bottom: 3rem;
  }
}
.portfolioImage {
  width: 100%;
  object-fit: cover;
  z-index: 97;
  object-position: center;
  @media (min-width: 820px) {
    width: 33%;
  }
}

.contactText {
  height: calc(100% - 10rem);
  width: 80%;
  z-index: 99;
  position: absolute;
  color: whitesmoke;
  left: 50%;
  transform: translateX(-50%);
  mix-blend-mode: difference;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  font-family: outfit;
  > p {
    font-family: outfit-thin;
    margin: 0;
  }
  @media (min-width: 570px) {
    width: 25rem;
  }
  @media (max-height: 610px) {
    font-size: 13px;
    width: 15rem;
  }
}

.instagramButton {
  width: 100%;
  fill: whitesmoke;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: none;
  &:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  &:active {
    > svg > path {
      fill: grey;
    }
  }
}

.emailButton {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  &:active {
    > svg > path {
      fill: grey;
    }
  }
}

.emailButton svg,
.emailButton .linkCopiedText {
  position: absolute;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0;
  visibility: hidden;
  fill: whitesmoke;
}

.emailButton svg {
  opacity: 1;
  visibility: visible;
}

.emailButton.copied svg {
  opacity: 0;
  visibility: hidden;
}

.emailButton.copied .linkCopiedText {
  color: whitesmoke;
  opacity: 1;
  visibility: visible;
}

.linkCopiedText {
  font-family: outfit-thin;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.out-top {
  animation: rotate 20s linear infinite;
  transform-origin: 13px 25px;
}
.in-top {
  animation: rotate 10s linear infinite;
  transform-origin: 13px 25px;
}
.out-bottom {
  animation: rotate 25s linear infinite;
  transform-origin: 84px 93px;
}
.in-bottom {
  animation: rotate 15s linear infinite;
  transform-origin: 84px 93px;
}

.bubblesBackground {
  z-index: 10;
  height: 100%;
  opacity: 1;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 10rem;
  background: #ffffff;
  box-shadow: inset -7px 7px 14px #e0e0e0, inset 7px -7px 14px #ffffff;
}

.footerText {
  font-family: outfit-thin;
  padding-bottom: 5rem;
  font-size: 1.5rem;
}
